import React from "react"
import { ThemeProvider } from "styled-components"
// import { CartProvider } from "react-use-cart"

import { StoreProvider } from "../../store"
import theme from "../../theme"

export default function RootElement({ children }) {
  return (
    <StoreProvider>
      {/* <CartProvider> */}
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
      {/* </CartProvider> */}
    </StoreProvider>
  )
}
