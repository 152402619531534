import mq from "./mq"
import fonts from "./fonts"
import colors from "./colors"
import zindex from "./zindex"
import easings from "./easings"
import animations from "./animations"
import grid from "./grid"
import mixins from "./mixins"

export default {
  mq,
  fonts,
  colors,
  zindex,
  easings,
  animations,
  grid,
  mixins,
}
