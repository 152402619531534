/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { CartProvider } from "react-use-cart"

import RootElement from "./src/components/root-element"

// wrapRootElement

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}

// wrapPageElement

export const wrapPageElement = ({ element }) => {
  return <CartProvider>{element}</CartProvider>
}

// onRouteUpdate

// export function onRouteUpdate({ location, prevLocation }) {
//   // checks if route has changed
//   if (location && prevLocation) {
//     // triggers `gatsbyRouteChange` event for `window`
//     const event = document.createEvent(`Event`)
//     event.initEvent(`gatsbyRouteChange`, true, true)
//     window.dispatchEvent(event)
//   }
// }
