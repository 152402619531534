import { css } from "styled-components"
import { rem } from "polished"

const hideScrollbar = () => css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const colorizeScrollbar = ({ size = rem(5), color } = {}) => css`
  &::-webkit-scrollbar {
    width: ${size};
    height: ${size};
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => color || theme.colors.greyLight};
  }
`

export default { hideScrollbar, colorizeScrollbar }
