module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"lt","name":"UnderPress","short_name":"UnderPress","description":"Šventinė spauda, grafinis dizainas, spaustuvė Klaipėdoje.","theme_color":"#fffd00","background_color":"#fff","icon":"static/images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://a5b89c2b393a4e60a40507aff23bdb9f@o501062.ingest.sentry.io/5581695","environment":"production","autoSessionTracking":true,"sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wp.underpress.lt/graphql","schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":60000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
