// https://mycolor.space/?hex=%23FFFD00&sub=1

let colors = {
  white: `#fff`,
  black: `#222`,
  // greyDark: `#444`,
  grey: `#666`,
  greyLight: `#aaa`,
  greyLighter: `#e6e6e6`,
  greyLightest: `#f2f2f2`,
  yellow: `#fffd00`,
  yellowLighter: `#f8f2cb`,
  yellowLightest: `#fcfaee`,
  brown: `#494737`,
  red: `#bc5a40`,
  redLight: `#ffc5a5`,
  redLightest: `#fbebde`,
}

colors = {
  ...colors,
  foreground: colors.black,
  background: colors.white,
}

export default colors
