exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-cart-complete-js": () => import("./../../../src/templates/cart-complete.js" /* webpackChunkName: "component---src-templates-cart-complete-js" */),
  "component---src-templates-cart-js": () => import("./../../../src/templates/cart.js" /* webpackChunkName: "component---src-templates-cart-js" */),
  "component---src-templates-cart-shipping-js": () => import("./../../../src/templates/cart-shipping.js" /* webpackChunkName: "component---src-templates-cart-shipping-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-portfolio-post-js": () => import("./../../../src/templates/portfolio-post.js" /* webpackChunkName: "component---src-templates-portfolio-post-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-services-post-js": () => import("./../../../src/templates/services-post.js" /* webpackChunkName: "component---src-templates-services-post-js" */),
  "component---src-templates-shop-category-js": () => import("./../../../src/templates/shop-category.js" /* webpackChunkName: "component---src-templates-shop-category-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-shop-product-js": () => import("./../../../src/templates/shop-product.js" /* webpackChunkName: "component---src-templates-shop-product-js" */)
}

